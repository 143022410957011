import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../../../components/Layout"
import styled from "styled-components"

import ProjectBanner from "../../../images/projects/ens-time-keepers/banner.png"
import MetaImg from "../../../images/projects/ens-time-keepers/meta.png"

import HourHouseStats from './hourhousestats'
import ConnectWallet from './1_connect_wallet'
import ValidateTimekeeper from './2_validate_timekeeper'
import ConstructTweet from './3_construct_tweet'
import ShowTweet from './4_show_tweet'

interface IStep {
    selected: boolean;
    canSelect: boolean;
}

const Title = styled.h3`
    display: block;
    margin: 0 0 1rem 0;
`
const ProjectBannerImg = styled.img`
    margin: 0 0 1em 0;
`
const SubTitle = styled.h4`
    display: block;
    margin: 0 0 1.5rem 0;
    color: #999;
`
const Stepper = styled.div`
    display: block;
    border: 1px solid #999;
    width: 100%;
    text-align: center;
    padding: 0 2em;
    margin: 2em 0;
`
const Steps = styled.ul`
    list-style-type: none;
`
const Step = styled.li<IStep>`
    display: inline-block;
    padding: 0.5em 1em;
    background: ${props => props.selected ? `#353535` : `transparent`};

    :hover {
        cursor: ${props => props.canSelect ? `pointer` : `not-allowed`};
    }
`
const ResetButton = styled.div<IStep>`
    display: block;
    margin: 2em 0;
    padding: 0.5em 1em;
    background: #9B6161;
    width: fit-content;

    :hover {
        cursor: ${props => props.canSelect ? `pointer` : `not-allowed`};
    }
`
const Disclaimer = styled.div`
    padding: 5em 0 0 0;
    font-size: 80%;
    font-style: italic;
    color: #999;
`

interface IState {
    step: number; // holds the current step the user is in the process
    web3: any;
    ensLabel: string;
    tweetId: string;
    tweetText: string;
}

const DEFAULT_STATE = {
    step: 0,
    web3: {
        activeAddress: "",
        provider: null,
        signer: null
    },
    ensLabel: "",
    tweetId: "",
    tweetText: ""
}

class EnsTimeKeepers extends React.Component<{}, IState> {
    state: IState = DEFAULT_STATE;

    constructor() {
        super({});

        this.renderSwitch = this.renderSwitch.bind(this);
        this.updateState = this.updateState.bind(this);
    }

    updateState(args: any) {
        this.setState({
            ...this.state,
            ...args,
            step: args.nextStep
        })
    }

    renderSwitch(step: number) {
        switch(step) {
            default :
            case 0 :
                return <ConnectWallet
                            callback={this.updateState} 
                        />
            case 1 :
                return <ValidateTimekeeper
                            callback={this.updateState} 
                            web3={this.state.web3}
                        />
            case 2 :
                return <ConstructTweet
                            callback={this.updateState} 
                            web3={this.state.web3}
                            ensLabel={this.state.ensLabel}
                        />
            case 3 :
                return <ShowTweet
                            tweetId={this.state.tweetId}
                            tweetText={this.state.tweetText}
                        />
        }
    }

    render() {
        const date = new Date();
        const currentHour = date.getUTCHours();
        return (
            <Layout>
                <Helmet>
                <title>ENS TimeKeepers Community Twitter Account | Harry Denley</title>
                <meta name="description" content="You are an ENS TimeKeeper? You have shared access to this Twitter account" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://harrydenley.com/projects/ens-time-keepers/" />
                <meta property="og:title" content="ENS TimeKeepers - Harry Denley" />
                <meta property="og:description" content="ENS TimeKeepers Community Twitter Account" />
                <meta property="og:image" content={`https://harrydenley.com${MetaImg}`} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@sniko_" />
                <meta name="twitter:title" content={`ENS TimeKeepers - Harry Denley`} />
                <meta name="twitter:description" content={`You are an ENS TimeKeeper? You have shared access to this Twitter account!`} />
                <meta name="twitter:image" content={`https://harrydenley.com/${MetaImg}`} />
                </Helmet>
                <Title>
                    <Link to="/">~/</Link>&nbsp;
                    ENS Time Keepers Community Twitter Account
                </Title>
                <ProjectBannerImg src={ProjectBanner} />
                <SubTitle>
                    Gain access to a Twitter account for the hours of your #timekeeper ENS names. Represent the hour (UTC) of your #TimeKeeper to crypto twitter!
                </SubTitle>
                <SubTitle>
                    This requires no gas fees and nothing is put on-chain. All is required is for you to sign a message to cryptographically ensure ownership of the Ethereum address that holds the #timekeeper ENS name.
                </SubTitle>

                <HourHouseStats />

                Current hour: {currentHour}H (UTC+0)
                
                <Stepper>
                    <Steps>
                        <Step 
                            onClick={() => this.state.step > 0 && this.updateState({nextStep: 0})} 
                            selected={this.state.step == 0}
                            canSelect={this.state.step > 0}
                        >
                            Connect Wallet
                        </Step>
                        <Step 
                            // onClick={() => this.state.step > 1 && this.updateState({nextStep: 1})} 
                            selected={this.state.step == 1}
                            canSelect={false && this.state.step > 1}
                        >
                            Validate TimeKeeper Membership
                        </Step>
                        <Step 
                            // onClick={() => this.state.step > 2 && this.updateState({nextStep: 2})} 
                            selected={this.state.step == 2}
                            canSelect={false && this.state.step > 2}
                        >
                            Tweet!
                        </Step>
                    </Steps>
                </Stepper>

                {this.renderSwitch(this.state.step)}

                {this.state.step > 0 && <ResetButton onClick={() => this.state.step > 0 && this.updateState({nextStep: 0})} selected={false} canSelect={true}>Start Over</ResetButton>}

                <Disclaimer>
                    There is no expectation of the bot not getting suspended. All #TimeKeepers can tweet and I do expect some grief attacks to get the account suspended. Don't {' '}
                    take this bot seriously and especially don't buy a #TimeKeeper ENS name because I made this (read: don't plan your financial decisions based on my weekend project){' '}
                    The creator of this is not even a #TimeKeeper. He made this project for fun over a weekend.

                    <br /><br />

                    IF you like this project, consider donating to me 😇  Donation stuff is in the footer of this website, just click a tier and go through the Web3 prompts.

                    <br /><br />

                    When you send a tweet through this, the backend logs the following: tweet_id (generated by Twitter), timekeeper_ens_name (the ENS name you authenticate with), timekeeper_0x_address (the Ethereum address you sign the transaction with), tweet (the body of the tweet). Whilst server logs do have IP addresses that could be used to link your 0x address to your IP, I do not actively look at these logs. Please refer to {' '}
                    <a href="https://www.papertrail.io/legal" target="_blank" rel="nofollow noreferer">Papertrail</a> terms of service first.
                </Disclaimer>
            </Layout>
        );
    }
};

export default EnsTimeKeepers;
